import React from 'react'
import styled from 'styled-components'
import { whatsapp } from '../icons'

const FloatingBox = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 10px;
`

const WhatsAppIcon = styled.img`
    margin: 0 5px;
`
const WhatsAppLink = styled.a`
    color: #61FA81;
`

const WhatsApp = () => (
    <FloatingBox>
        <WhatsAppIcon src={whatsapp} />
        <WhatsAppLink
            href="https://api.whatsapp.com/send?phone=5521981008804&amp;text=Fala,%20Thiago!%0D%0AVi%20seu%20site%20sobre%20Crokinole"
            target="_blank"
        >
            WhatsApp
        </WhatsAppLink>

    </FloatingBox>
)

export default WhatsApp